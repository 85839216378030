<template>
  <section>
    <form
      v-on:submit.prevent
      id="form_reason"
      class="form"
      role="form"
      ref="form"
    >
      <div class="row">
        <div class="col-md-4">
          <h4>{{ $t("reason_list") }}</h4>
          <small class="label label-warning" v-if="isDirty">
            <i class="icon fa fa-warning"></i> {{ $t("warning") }}:
            {{ $t("confirmation_pending_save_message") }}
          </small>
        </div>
        <div class="col-md-8">
          <div class="btn-group" style="float: right">
            <button
              type="button"
              class="btn btn-default"
              :disabled="selectionExists"
              @click="addItem()"
              :title="$t('add_reason')"
            >
              <i class="fa fa-plus"></i>
              {{ $t("add_reason") }}
            </button>
            <button
              id="export"
              class="btn btn-default"
              :disabled="isDirty || (reason_list && reason_list.length == 0)"
              @click="exportData"
              :title="$t('hints.button_export')"
            >
              <i class="fa fa-file-excel-o" />
              {{ $t("export") }}
            </button>
            <button
              id="import"
              class="btn btn-default"
              :disabled="isDirty || selectionExists"
              @click="importReasons"
              :title="$t('hints.button_import')"
            >
              <i class="fa fa-upload" />
              {{ $t("import") }}
            </button>
            <button
              id="remove"
              class="btn btn-danger"
              :disabled="!selectionExists"
              @click="removeSelectedItems"
              :title="$t('remove')"
            >
              <i class="fa fa-times" />
              {{ $t("remove") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="!isValid || !isDirty || selectionExists"
              :title="$t('save')"
              @click="save()"
            >
              <i class="fa fa-check"></i>
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th style="width: 5%">
                  <Checkbox
                    v-if="this.reason_list.length > 0"
                    v-model="toggle_select_all"
                    @click="toggleSelectAll"
                  />
                </th>
                <th style="width: 10%">
                  {{ $t("value") }}
                  <Tooltip :title="$t('hints.reason_list_value')" />
                </th>
                <th style="width: 60%">
                  {{ $t("reason_text") }}
                  <Tooltip :title="$t('hints.reason_list_text')" />
                </th>
                <th style="width: 5%">
                  {{ $tc("color", 1) }}
                  <Tooltip :title="$t('hints.reason_list_color')" />
                </th>
                <th style="width: 15%">
                  {{ $t("is_planned_stop") }}
                  <Tooltip :title="$t('hints.reason_list_is_planned_stop')" />
                </th>
                <th style="width: 5%"></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="!reason_list.length">
                <tr>
                  <td colspan="6">
                    <div class="alert alert-info">
                      <h4>
                        <i class="icon fa fa-info"></i> {{ $t("warning") }}
                      </h4>
                      {{ $t("empty_list") }}
                      {{ $t("click_add_to_start") }}
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else v-for="(reason, index) in reason_list">
                <tr v-bind:key="index">
                  <td style="width: 5%">
                    <Checkbox
                      v-if="canEdit(reason)"
                      :id="'item_' + index + '_selected'"
                      class="selectedItem"
                      v-model="reason_list[index].selected"
                    />
                  </td>
                  <td
                    style="width: 10%"
                    :class="{ 'has-error': duplicated(index, 'value', true) }"
                  >
                    <InputNumber
                      :id="'item_' + index + '_value'"
                      class="selectedItemInputReason form-control text-left"
                      v-model="reason_list[index].value"
                      customPattern="\d+"
                      :disabled="reason_list[index].id ? true : false"
                      @keydown.prevent.tab.exact="tab"
                      @keydown.prevent.shift.tab="shiftTab"
                      @blur="onBlur($event)"
                    />
                  </td>
                  <td
                    style="width: 60%"
                    :class="{ 'has-error': duplicated(index, 'text', false) }"
                  >
                    <InputString
                      :id="'item_' + index + '_text'"
                      v-model="reason_list[index].text"
                      class="form-control text text-left"
                      :disabled="!canEdit(reason)"
                      :maxSize="255"
                      @keydown.prevent.tab.exact="tab"
                      @keydown.prevent.shift.tab="shiftTab"
                    />
                  </td>
                  <td style="width: 5%">
                    <ColorPicker
                      :id="'item_' + index + '_color'"
                      v-model="reason_list[index].color"
                      :pickerStyle="{ left: '-200px' }"
                    />
                  </td>
                  <td style="width: 15%">
                    <Checkbox
                      v-if="canEdit(reason)"
                      :id="'item_' + index + '_is_planned_stop'"
                      v-model="reason_list[index].is_planned_stop"
                    />
                  </td>
                  <td style="width: 5%">
                    <button
                      v-if="canEdit(reason)"
                      type="button"
                      class="btn btn-danger"
                      :title="$t('remove')"
                      :disabled="selectionExists"
                      @click="removeItem(index)"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="6">
                  <button
                    type="button"
                    class="btn btn-default"
                    :disabled="selectionExists"
                    @click="addItem()"
                    :title="$t('add_reason')"
                  >
                    <i class="fa fa-plus"></i>
                    {{ $t("add_reason") }}
                  </button>
                  <button
                    type="button"
                    @click="topFunction()"
                    class="btn btn-default"
                  >
                    <i class="fa fa-level-up"></i>
                    {{ $t("back_to_top") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>

    <ModalImportFile
      :entity="importEntity"
      @close="clearImportEntity"
      @resource_imported="resourceImported"
    />
  </section>
</template>

<script>
import FormBase from "@/components/registration/form-base.vue";
import Tooltip from "@/components/tooltip.vue";
import InputString from "@/components/input-string.vue";
import InputNumber from "@/components/input-number.vue";
import ColorPicker from "@/components/editor/color-picker.vue";
import Checkbox from "@/components/base/checkbox.vue";
import ReasonService from "@/services/reason.js";
import ModalImportFile from "@/components/modal-import-file.vue";

function defaultData() {
  return {
    original_reason_list: [],
    reason_list: [],
    ready: false,
    toggle_select_all: false,
    importEntity: ""
  };
}

export default {
  name: "FormReason",
  extends: FormBase,
  // i18n: { messages },
  components: {
    Tooltip,
    InputString,
    InputNumber,
    ColorPicker,
    ModalImportFile,
    Checkbox
  },
  props: {
  },
  data() {
    return defaultData();
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"];
    },
    hasErrors() {
      if (this.reason_list.length == 0) return false;
      return this.reason_list.filter((item, index) => {
        return this.duplicated(index, 'value', true) || this.duplicated(index, 'text', false);
      }).length > 0;
    },
    isValid() {
      if (this.hasErrors) {
        return false;
      }
      return this.reason_list.filter((item) => {
        return ((item.value + '').trim() == '' || item.text.trim() == '');
      }).length == 0;
    },
    isDirty() {
      if (this.original_reason_list.length != this.reason_list.length) return true;

      for (let i = 0; i < this.original_reason_list.length; i++) {
        if (!this.reason_list[i]) return true;

        if (
          this.original_reason_list[i].value != this.reason_list[i].value ||
          this.original_reason_list[i].text != this.reason_list[i].text ||
          this.original_reason_list[i].is_planned_stop != this.reason_list[i].is_planned_stop ||
          this.original_reason_list[i].color != this.reason_list[i].color
        ) return true;
      }

      return false;
    },
    payload() {
      return {
        contract_id: this.contract.id,
        values: this.reason_list
      };
    },
    multiSelection() {
      return {
        values: this.reason_list.filter((i) => i.selected == true).map((i) => i.id)
      }
    },
    selectionExists() {
      return false || this?.multiSelection?.values?.length > 0;
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    },
    multiSelection(n) {
      if (this.reason_list.length > 0 && this.reason_list.length == n?.values?.length) {
        this.toggle_select_all = true;
      } else if (this.toggle_select_all) {
        this.toggle_select_all = false;
      }
    }
  },
  methods: {
    setup() {
      let items = this.$store.getters["onlinee/reasons"];

      if (items && items.length) {
        this.$set(this, 'reason_list', items);
        this.$set(this, 'original_reason_list', JSON.parse(JSON.stringify(this.reason_list)));
        this.ready = true;
        return;
      }

      this.fetchItems();
    },
    fetchItems() {
      this.$store.dispatch("onlinee/fetchReasons").then((result) => {
        if (!this.ready) {
          result = result.map((item) => {
            return {
              ...item,
              ...{
                selected: false
              }
            }
          });

          this.$set(this, 'reason_list', (result || []));
          this.$set(this, 'original_reason_list', JSON.parse(JSON.stringify(this.reason_list)));
          this.ready = true;
          return;
        }

        if (this.reason_list && this.reason_list.length) {
          this.$set(this, 'reason_list', this.reason_list.map((item) => {
            return {
              ...item,
              ...{
                id: result.find((i) => i.value == item.value).id,
                selected: false
              }
            }
          }));
        } else {
          this.$set(this, 'reason_list', result);
        }

        this.$set(this, 'original_reason_list', JSON.parse(JSON.stringify(this.reason_list)));

        this.ready = true;
      });
    },
    addItem() {
      this.reason_list.push(
        {
          id: null,
          value: "",
          text: "",
          color: this.getNewColor(),
          is_planned_stop: false,
          selected: false
        }
      );

      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
        let items = document.querySelectorAll('.selectedItemInputReason');
        items[items.length - 1].focus();
      }, 100);
    },
    getNewColor() {
      let notUsed = this.$utils.colors().filter((color) => {
        return this.reason_list.map((i) => { return i.color }).indexOf(color) == -1;
      });
      if (notUsed.length > 0) return notUsed[0];
      return this.$utils.randomColor();
    },
    removeItem(index) {
      this.reason_list.splice(index, 1);

      if (this.reason_list.length == 0) {
        this.toggle_select_all = false;
      }
    },
    duplicated(index, field, is_integer) {
      let check_value = this.reason_list[index][field];
      if (typeof check_value !== "undefined" && !(check_value + "").trim()) return true;

      try {
        if (is_integer) {
          check_value = parseInt(check_value) + "";
          return this.reason_list.filter((item) => {
            return (parseInt(item[field]) + "").trim() == (check_value + "").trim();
          }).length > 1 ? true : false;
        } else {
          if (!check_value) return false;
          return this.reason_list.filter((item) => {
            return (item[field] + "").trim() == (check_value + "").trim();
          }).length > 1 ? true : false;
        }
      } catch {
        return true;
      }
    },
    save() {
      this.busy = true;
      this.$store.dispatch("onlinee/saveReasons", this.payload).then((ret) => {
        this.busy = false;
        if (this.validateSaveResponse(ret)) {
          this.reason_list = this.reason_list.map((item) => {
            return {
              id: ret.find((i) => i.value == item.value).id,
              value: item.value,
              text: item.text,
              color: item.color,
              is_planned_stop: item.is_planned_stop,
              selected: false
            }
          });
          this.showAlert();
        } else {
          if (ret && ret.length == 0) {
            let txt = this.$t("you_have_saved_n_items", {
              count: ret.length
            });
            this.alert = {
              title: this.$t("save"),
              text: txt,
              html: txt,
              type: "success"
            };
          }
          this.showAlert();
        }

        this.$set(this, 'original_reason_list', JSON.parse(JSON.stringify(this.reason_list)));
      });
    },
    tab() {
      let $e = document.activeElement;
      let inputSet =
        ($e && ($e.closest("#form_reason"))) || null;
      if (inputSet) {
        let allInputs = Array.from(inputSet.querySelectorAll("input:not([disabled])"));
        let nextIndex = allInputs.indexOf($e);
        if (nextIndex >= allInputs.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex++;
        }
        allInputs[nextIndex].focus();
        allInputs[nextIndex].select();
      }
    },
    shiftTab() {
      let $e = document.activeElement;
      let inputSet =
        ($e && ($e.closest("#form_reason"))) || null;
      if (inputSet) {
        let allInputs = Array.from(inputSet.querySelectorAll("input:not([disabled])"));
        let nextIndex = allInputs.indexOf($e);
        if (nextIndex == 0) {
          nextIndex = allInputs.length - 1;
        } else {
          nextIndex--;
        }
        allInputs[nextIndex].focus();
        allInputs[nextIndex].select();
      }
    },
    onBlur(e) {
      if (!e) return;
      e.target.value = e.target.value.replaceAll('-', '').replaceAll('.', '');
      while (e.target.value.length > 1 && e.target.value[0] == '0') {
        e.target.value = e.target.value.substr(1);
      }
    },
    removeSelectedItems() {
      this.reason_list = this.reason_list.filter((i) => i.selected == false);
    },
    importReasons() {
      this.importEntity = "reason";
    },
    clearImportEntity() {
      this.importEntity = "";
    },
    resourceImported(response, entity) {
      let msg = this.$t("success_import_message");
      this.validateSaveResponse(response, msg);
      this.showAlert();

      if (response && response instanceof Array) {
        this.$emit("resource_imported", entity);
        this.$store.dispatch("onlinee/resetReasons").then(() => {
          this.$set(this, 'reason_list', []);
          this.$set(this, 'original_reason_list', []);
          this.fetchItems();
        });
      }
    },
    exportData() {
      let ids = this?.multiSelection?.values ? this?.multiSelection?.values + '' : '';
      let params =
        `contract_id=${this.contract.id}&` +
        `ids=${ids}`;
      this.service.export(params, `reasons.csv`);
    },
    toggleSelectAll() {
      this.$set(this, 'reason_list', this.reason_list.map((i) => {
        return {
          ...i,
          ...{
            selected: this.canEdit(i) ? !this.toggle_select_all : false
          }
        }
      }));
    },
    canEdit(item) {
      if (!item.id) return true;
      if (item.value == '0' || item.value == '1') return false;
      return true;
    },
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  },
  beforeCreate() {
    this.service = new ReasonService();
  },
  created() {
    this.setup();
  }
};

document.addEventListener("keydown", function(e) {
  if (e.target.classList.contains('text') && e.key == ";") {
    e.preventDefault();
  }
});
</script>

<style>
div.checkbox {
  margin: 0;
}
.selectedIem {
  margin-top: 5px;
}
</style>